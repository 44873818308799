<template>
  <div class="container-fluid say">
    <div class="say-overflow"></div>
    <div
      class="text-center mb-5"
      style="z-index: 99999 !important;position: sticky;"
    >
      <div class="border-title">
        <h2
          style="color:#3e454c;background:#ffffff;padding: 0 42px;border-radius: 5px;"
        >
          اعمال سابقه
        </h2>
      </div>
    </div>

    <div class="container">
      <carousel
        :autoplay="true"
        :nav="false"
        :items="1"
        :loop="true"
        :stagePadding="w > 580 ? 500 : 0"
        :margin="w > 580 ? 30 : 0"
      >
        <!-- <div class=" text-center">
          <img src="../../assets/p.jpg" class="card-img-top" />
          <div class="test-inner-detail">
            <h4 style="color:#ffffff">فلاتر مياه</h4>

            <span style="color:#ffffff">الفلتر</span>
          </div>
        </div>
        <div class=" text-center">
          <img src="../../assets/p.jpg" class="card-img-top" />
          <div class="test-inner-detail">
            <h4 style="color:#ffffff">فلاتر مياه</h4>

            <span style="color:#ffffff">الفلتر</span>
          </div>
        </div> -->
        <div class=" text-center" v-for="item of list" :key="item.id">
          <img
            :src="baseUploadURL + item.image"
            class="card-img-top"
            style="height:50vh;"
          />
          <div class="test-inner-detail">
            <h4 style="color:#ffffff">{{ item.name }}</h4>

            <span style="color:#ffffff">{{ item.text }}</span>
          </div>
        </div>
        <template slot="prev"><span class="prev">prev</span></template>
        <template slot="next"><span class="next">next</span></template>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';

export default {
  name: 'say',
  components: { carousel },
  props: {
    list: [],
  },
  data() {
    return {
      baseUploadURL: this.$baseUploadURL,
      p: 200,
      w: 0,
    };
  },
  created() {
    this.w = window.innerWidth;
  },
};
</script>

<style></style>
