<template>
  <div>
    <!-- <div
      id="offer"
      v-if="offer"
      style="position: fixed;bottom: 23px;left: 23px;z-index: 9999999999;box-shadow: 3px 2px #8888881f;"
    >
      <router-link to="/page/day-offer">
        <i
          class="fa fa-times"
          @click="offer = false"
          style="position: absolute;right: 4px;top: 2px;"
        ></i>
        <img src="https://aqua-trust.com/files/63361_1687718106.png" />
      </router-link>
    </div> -->

    <slider v-if="slider.length > 0" :list="slider" />
      
    <welcome />
    <products />
    <say v-if="lastwork.length > 0" :list="lastwork" />
    <!-- <conactus /> -->
    <!-- <a href="#" id="toTop" v-show="visible" @click="backToTop"
      ><span id="toTopHover" style="opacity: 0;"></span
      ><i class="fa fa-long-arrow-up"></i
    ></a> -->
  </div>
</template>

<script>
import slider from './home/slider';
import welcome from './home/welcome';
import conactus from './home/conactus';
import products from './home/products';
import say from './home/say';
import dayoffer from './home/dayoffer.vue'

export default {
  components: {
    slider,
    welcome,
    conactus,
    products,
    say,
    dayoffer,
  },
  data() {
    return {
      offer: true,
      slider: [],
      products: [],
      lastwork: [],
    };
  },
  created() {
    this.$http.get('slider').then(
      (res) => {
        this.slider = res.data.slider;
        // this.products = res.data.products;
        this.lastwork = res.data.lastwork;
      },
      (err) => {
        console.log(err);
      },
    );
  },
};
</script>
