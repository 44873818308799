<template>
  <div class=" slider">
    <!-- <carousel :autoplay="true" :nav="false" :items="1" :loop="true">
      <img src="../../assets/p.jpg" class="card-img-top" />
      <img src="../../assets/p.jpg" class="card-img-top" />
      <img src="../../assets/p.jpg" class="card-img-top" />
      <img src="../../assets/p.jpg" class="card-img-top" />
      <img src="../../assets/p.jpg" class="card-img-top" />
    </carousel> -->
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      class="carousel-slide-b"
      img-width="1024"
      img-height="480"
      fade
    >
      <b-carousel-slide
        v-for="item of list"
        :key="item.id"
        :img-src="baseUploadURL + item.image"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'slider',
  props: {
    list: [],
  },
  data() {
    return {
      baseUploadURL: this.$baseUploadURL,
    };
  },
};
</script>

<style></style>
