<template>
  <div class="container-fluid conactus">
    <div class="row">
      <div class="col-md-5">
        <img class="img-fluid" src="../../assets/home-contactus.png" />
      </div>
      <div class="col-md-7">
        <div class="container">
          <p>اتصل بنا</p>
          <h3>
            It's Time to Protect Your Family
          </h3>
          <form action="" method="post">
            <div class="row mb-3">
              <div class="col">
                <input
                  type="text"
                  style="height: 54px;"
                  class="form-control"
                  placeholder="Enter Name"
                />
              </div>
              <div class="col">
                <input
                  type="text"
                  style="height: 54px;"
                  class="form-control"
                  placeholder="Enter Phone"
                />
              </div>
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                placeholder="Enter text"
                rows="6"
              ></textarea>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-more">ارسال</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'conactus',
};
</script>

<style></style>
