<template>
  <div class="container products">
    <div class="text-center mb-5">
      <div class="border-title">
        <h2
          style="
        color: rgb(255, 255, 255);
          background: rgb(10, 153, 249) none repeat scroll 0% 0%;
          padding: 0 42px;border-radius: 5px;direction: rtl;"
        >
          منتجات MSTEC
        </h2>
      </div>

      <p style="color:#1d3750;">
        جميع منتاجتنا طبيه من اجود الخامات
      </p>
    </div>
    <b-modal
      id="modal-home"
      size="lg"
      centered
      cancel-title="اغلاق"
      ok-title="طلب المنتج"
      title="طلب المنتج"
      @ok="handleOk"
    >
      <div class="form-group">
        <label for="Products-location">الاسم الكامل</label>

        <input
          type="text"
          name="name"
          v-model="body.name"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="Products-location">الهاتف</label>

        <input
          type="text"
          name="phone"
          v-model="body.phone"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="Products-location">طريقه الدفع</label>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="baytype"
            v-model="body.baytype"
            id="baytype1"
            :value="false"
            checked
          />
          <label class="form-check-label" for="baytype1">
            كاش
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="baytype"
            id="baytype2"
            v-model="body.baytype"
            :value="true"
          />
          <label class="form-check-label" for="baytype2">
            اون لاين
          </label>
        </div>
      </div>

      <div class="form-group">
        <label for="Products-status">ملاحظات</label>

        <textarea
          name="note"
          v-model="body.note"
          rows="3"
          class="form-control"
        ></textarea>
      </div>
    </b-modal>
    <div v-for="item of list" :key="item.id" style="direction: rtl;">
      <div class="row" v-if="item.id!=5">
        <div class="col-md-12 text-right">
          <div class="border-title">
            <h2
              style="
          color: rgb(10, 153, 249);
            padding: 42px 42px;"
            >
              {{ item.name }}
            </h2>
          </div>
        </div>

        <div
          class="col-md-4"
          v-for="itm of item.products"
          :key="itm.id"
          :style="width < 580 ? 'padding:10%' : ''"
        >
          <div class="card text-center mt-4">
            <img :src="baseUploadURL + itm.logo" class="card-img-top" />

            <div class="card-body">
              <h5 class="card-title">
                {{ itm.name }}
                <span class="pricex" v-if="itm.newPrice > 0">
                (
                  {{ itm.newPrice }} ج.م
                  <del>{{ itm.price }} ج.م</del>
                )
                </span>
                <span class="pricex" v-else>
                  (
                  {{ itm.price }} ج.م
                  )
                </span>
              </h5>
              <div class="cart-btn">
                <router-link :to="'/product/' + itm.slug" class="more"
                  >التفاصيل</router-link
                >
                <a
                  v-b-modal.modal-home
                  @click="body.products_id = itm.id"
                  class="pay"
                >
                  <i class="fa fa-cart-plus"></i>طلب
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="text-center"
      style="margin-top: 30px;"
      v-if="!noMore"
      @click="getMore"
    >
      <a class="btn btn-more">المزيد من المنتاجات</a>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'products',
  // props: {
  //   list: [],
  // },
  data() {
    return {
      baseUploadURL: this.$baseUploadURL,
      noMore: false,
      skip: 6,
      body: {
        name: null,
        phone: null,
        note: null,
        baytype: false,
        products_id: null,
      },
      list: [],
      width: 0,
    };
  },
  methods: {
    getData() {
      this.$http.get(`categories`).then(
        (res) => {
          // for (const item of res.data) {
          this.list = res.data;
          // }
          // this.skip = this.skip + 6;
          // if (res.data.length) this.noMore = true;
        },
        (err) => {
          console.log(err);
        },
      );
    },
    handleOk() {
      if (this.body.name && this.body.phone) {
        this.$http.post(`order/add`, this.body).then(
          (res) => {
            this.$swal.fire({
              icon: 'success',
              title: 'Success',
              text: `تم طلب المنتج بنجاح وسيتم تحويلك على صفحه الدفع`,
              showConfirmButton: false,
              timer: 1500,
            });
            if (this.body.baytype) {
              setTimeout(() => {
                window.location.replace(res.data);
              }, 1500);
            } else {
              this.body.name = null;
              this.body.phone = null;
              this.body.note = null;
              this.body.baytype = false;
            }
          },
          () => {
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: `حدث خطأ فى الاتصال`,
              showConfirmButton: false,
              timer: 1500,
            });
          },
        );
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: `يرجى ملاء الحقول`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  created() {
    this.width = window.innerWidth;
    this.getData();
  },
};
</script>

<style>
.pricex {
  color: #f5a93f;

  font-size: 12px;
}
.pricex del {
  color: #e70202;
}
</style>
