import axios from 'axios';
import Vue from 'vue';
// export const baseURL = window.location.origin+'/cp';
export const baseURL ='https://mstec.org/cp';
export const baseUploadURL = baseURL + '/files/';

export const HTTP = axios.create({
  baseURL: `${baseURL}/api/v1/`,
  //  headers: {
  //    // Authorization: `Bearer ${user.token}`,
  //    crossdomain: true ,
  //   //  Vary:'Origin'
  //  },
});
