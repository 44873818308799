import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);
const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.user) {
    next();
    return;
  }
  next('/login');
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.user) {
    next();
    return;
  }
  next('/');
};

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () => import("../views/contactus.vue"),
    // beforeEnter: ifNotAuthenticated,
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("../views/register.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  {
    path: "/product/:id",
    name: "product",
    component: () => import("../views/product.vue"),
    // beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/category/:id",
    name: "category",
    component: () => import("../views/category.vue"),
    // beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/page/:id",
    name: "page",
    component: () => import("../views/page.vue"),
    // beforeEnter: ifNotAuthenticated,
  },
  // {
  //   path: "/pay/:id",
  //   name: "pay",
  //   component: () => import("../views/pay.vue"),
  //   // beforeEnter: ifNotAuthenticated,
  // },

  { path: '*', 
  component: Home, }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
