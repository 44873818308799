<template>
  <div class="container-fluid welcome">
    <div class="row">
      <div class="col-md-6" style="padding: 0;">
        <iframe
          src="https://www.youtube.com/embed/m4DJxFOx5GU?autoplay=1"
          style="width: 100%;height: 100%;"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-md-6">
        <div class="block-content wow fadeInRight animated animated">
          <div class="border-title">
            <h2
              style="color:#ffffff;border-radius: 5px;
              background:rgb(10, 153, 249) none repeat scroll 0% 0%;direction: rtl;"
            >
              مرحبا بكم فى MSTEC
            </h2>
          </div>
          <p style="color:#3e454c;direction: rtl;">
            MSTEC من الشركات الرائدة فى مجال تكنولوجيا معالجة المياه فى مصر
            والوطن العربى والتى تتميز بكفائة منتاجتها والتى تتناسب مع المنازل
            والشركات والمصانع وتتميز الشركة بضمان منتجاتها وخدمة مابعد البيع.
          </p>
          <h4 style="color:#1d3750;direction: rtl;">
            وحدات معالجة منزلية
          </h4>

          <p style="color:#3e454c;direction: rtl;">
            تناسب كل اسرة مصرية لتتمتع بشرب مياه صحية خالية من الملوثات غنية
            بالمعادن اجود خامات واقل سعر فى مصر
          </p>

          <h4 style="color:#1d3750;direction: rtl;">
            محطات تحلية
          </h4>

          <p style="color:#3e454c;direction: rtl;">
            مهندسون متخصصون فى انشاء محطات محالجة المياه للقرى والمصانع والقرى
            السياحية والمستشفيات
          </p>
        </div>
      </div>
    </div>



    <dayoffer/>
    <div class="custom-widget">
      <div class="shop-info" style="font-size: 14px;">
        <div class="row">
          <div class="col-sm-4">
            <img
              src="https://cdn.shopify.com/s/files/1/1436/4942/files/icon-free-shipping.png?1425470763171699748"
              alt="Free shipping"
            />
            <p>التوصيل السريع</p>
          </div>

          <div class="col-sm-4">
            <img
              src="https://cdn.shopify.com/s/files/1/1436/4942/files/icon-money.png?1425470763171699748"
              alt="Money"
            />
            <p>الدفع عند الإستلام</p>
          </div>

          <div class="col-sm-4">
            <img
              src="https://cdn.shopify.com/s/files/1/1436/4942/files/icon-delivery.png?1425470763171699748"
              alt="Delivery"
            />
            <p>الدفع الأولين</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import dayoffer from './dayoffer.vue';
export default {
  name: 'welcome',
  components: {
    dayoffer
  }
};
</script>

<style></style>
