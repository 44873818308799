<template>
  <footer>
    <div class="container">
      <nav>
        <ul>
          <li>
            <router-link to="/">الرئيسية</router-link>
          </li>
          <li v-for="item of list" :key="item.id">
            <router-link :to="`/page/${item.slug}`">{{
              item.name
            }}</router-link>
          </li>
          <!-- <li>
            <a>نبذة عنا</a>
          </li>
          <li>
            <a>العروض</a>
          </li> -->
          <li>
            <router-link to="/contactus">اتصل بنا</router-link>
          </li>
        </ul>
      </nav>
      <div class="store-btns" style="direction: rtl;">
        <!-- <a href="#" class="google-store">
          <img src="../assets/google-store.svg" alt="" />
        </a>
        <a href="#" class="app-store">
          <img src="../assets/app-store.svg" alt="" />
        </a> -->
        <!-- 50 مول اجياداكتوبرالحصريالحي الاولخلف سنترال الحي الاول -->
        <br />

        01027743886 - 01027743844
      </div>
      <!-- <div class="social-btns">
        <a href="#" class="facebook">
          <img src="../assets/facebook.svg" alt="" />
        </a>
        <a href="#" class="twitter">
          <img src="../assets/twitter.svg" alt="" />
        </a>
        <a href="#" class="instagram">
          <img src="../assets/instagram.svg" alt="" />
        </a>
      </div> -->

      <!-- <p class="copyrights" style="direction: rtl;">
        <span style="color:#05a9f9">MS TEC</span> أحدي شركات اكوا ترست
      </p> -->
      <p class="copyrights">
        جميع الحقوق محفوظة 2021 ©
        <a href="https://alwanlab.com" target="_blank">الوان لاب</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer',
  props: {
    list: [],
  },
};
</script>
