<template>
  <div id="app">
    <div id="loader" v-show="lo">
      <div class="loader">
        <span class="loader-inner-1"></span>
        <span class="loader-inner-2"></span>
        <span class="loader-inner-3"></span>
        <span class="loader-inner-4"></span>
      </div>
    </div>

    <app-header v-if="cat" :list="cat"></app-header>

    <router-view />

    <app-footer  v-if="page" :list="page"></app-footer>
    <!-- <vue-fab mainBtnColor="#3599DB" icon="share" style="right: 5%; bottom: 18%">
      <fab-item :idx="0" href="tel:01027743886" target="_blank" icon="phone" />
      <fab-item
        :idx="1"
        href="https://wa.me/01027743886"
        icon="https"
        target="_blank"
        color=" #1c4fa9"
      />
      <fab-item
        :idx="2"
        href="https://www.facebook.com/mstec.egy"
        target="_blank"
        icon="icon-facebook"
        color=" #1c4fa9"
      />
    </vue-fab> -->
    <div class="cox">
      <div class="cox-icon" v-show="sicon">
        <a href="tel:01027743886" target="_blank" class="cox-icon-svg">
          <i class="fa fa-phone" style="font-size: 31px; color: #1c4fa9"></i>
        </a>
      </div>

      <div class="cox-icon" v-show="sicon">
        <a
          href="https://wa.me/01027743886"
          target="_blank"
          class="cox-icon-svg"
        >
          <svg
            viewBox="0 0 90 90"
            fill="rgb(79, 206, 93)"
            width="32"
            height="32"
          >
            <path
              d="M90,43.841c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L0,90l7.975-23.522   c-4.023-6.606-6.34-14.354-6.34-22.637C1.635,19.628,21.416,0,45.818,0C70.223,0,90,19.628,90,43.841z M45.818,6.982   c-20.484,0-37.146,16.535-37.146,36.859c0,8.065,2.629,15.534,7.076,21.61L11.107,79.14l14.275-4.537   c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.857S66.301,6.982,45.818,6.982z M68.129,53.938   c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.495c-0.993-0.358-1.717-0.538-2.438,0.537   c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.333   c-3.219-2.848-5.393-6.364-6.025-7.441c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882   c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.977   c-0.902-2.15-1.803-1.792-2.436-1.792c-0.631,0-1.354-0.09-2.076-0.09c-0.722,0-1.896,0.269-2.889,1.344   c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.113c0.541,0.716,7.49,11.92,18.5,16.223   C58.2,65.771,58.2,64.336,60.186,64.156c1.984-0.179,6.406-2.599,7.312-5.107C68.398,56.537,68.398,54.386,68.129,53.938z"
            ></path>
          </svg>
        </a>
      </div>
      <div class="cox-icon" v-show="sicon">
        <a
          href="https://www.facebook.com/mstec.egy"
          target="_blank"
          class="cox-icon-svg"
        >
          <svg
            version="1.1"
            id="Capa_1"
            width="32"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 155.139 155.139"
            style="enable-background: new 0 0 155.139 155.139"
            xml:space="preserve"
          >
            <g>
              <path
                id="f_1_"
                style="fill: #010002"
                d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184
		c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452
		v20.341H37.29v27.585h23.814v70.761H89.584z"
              />
            </g>
          </svg>
        </a>
      </div>

      <div class="cox-icon" @click="sicon = !sicon">
        <a class="cox-icon-svg">
          <svg
            t="1624045906066"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1697"
            width="32"
            height="32"
          >
            <path
              d="M762.835739 679.096352c-53.771002 0-101.843294 24.769643-133.496529 63.485141l-233.045293-136.0575c23.29011-29.415127 37.317464-66.444208 37.317464-106.780296 0-37.38956-12.080765-71.907823-32.380463-100.194492l222.296734-125.942132c31.367985 43.091406 82.057672 71.265229 139.30495 71.265229 95.085087 0 172.443987-77.340092 172.443987-172.434584S857.920827 0 762.835739 0c-95.069414 0-172.428314 77.340092-172.428314 172.434584 0 24.688144 5.303751 48.144388 14.695025 69.40014l-228.992249 129.76008c-30.543584-27.424653-70.754289-44.273151-114.955344-44.273151-95.075684 0-172.434584 77.340092-172.434584 172.434584 0 95.075684 77.340092 172.434584 172.434584 172.434584 41.135412 0 78.882318-14.519487 108.563886-38.624594l239.577808 139.878583c-11.974188 23.459379-18.89226 49.968727-18.89226 78.111205 0 95.085087 77.3589 172.443987 172.428314 172.443987 95.085087 0 172.443987-77.3589 172.443987-172.443987C935.279727 756.486598 857.920827 679.096352 762.835739 679.096352zM762.835739 36.706217c74.848082 0 135.734636 60.87715 135.734636 135.728367S837.683821 308.16295 762.835739 308.16295c-74.851216 0-135.718963-60.87715-135.718963-135.725232S688.022138 36.706217 762.835739 36.706217zM261.176799 635.468929c-74.832409 0-135.728367-60.867747-135.728367-135.728367 0-74.848082 60.87715-135.728367 135.728367-135.728367s135.728367 60.87715 135.728367 135.728367C396.905166 574.601183 336.006073 635.468929 261.176799 635.468929zM762.835739 987.256168c-74.813601 0-135.718963-60.889689-135.718963-135.737771s60.867747-135.718963 135.718963-135.718963c74.848082 0 135.734636 60.870881 135.734636 135.718963S837.683821 987.256168 762.835739 987.256168z"
              p-id="1698"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/layout/header.vue";
import AppFooter from "@/layout/footer.vue";
export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      lo: true,
      sicon: false,
      cat: [],
      page: [],
    };
  },
  created() {
    const href = window.location.href;
    if (
      href.startsWith('https://mstec') == false &&
      href.startsWith('http://localhost') == false
    ) {
      window.location.href = 'https://mstec.org';
    }
    this.$http.get("sliderhedaer").then(
      (res) => {
        this.cat = res.data.cat;
        this.page = res.data.page;
        setTimeout(() => {
          this.lo = false;
        }, 1500);
      },
      (err) => {
        console.log(err);
      }
    );
  },
};
</script>

<style></style>
