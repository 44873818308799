<template>
  <div>
    <div v-if="visible" id="fsb_background" style="opacity: 1">
      <div
        id="fsb_bar"
        style="
          text-align: center;
          margin: 0px;
          padding: 6px 10px;
          height: auto;
          width: 100%;
          background-color: rgba(40, 214, 25, 0.73);
          color: rgb(242, 242, 242);
          font-size: 30px;
          line-height: 37.5px;
          font-family: Helvetica;
        "
      >
        <a
          id="fsb_close"
          style="
            transform: translateY(-50%);
            font-family: Helvetica, Arial, sans-serif;
            font-weight: bold;
            font-size: 18px;
            text-decoration: none;
            color: red;
          "
          @click="visible = false"
          >X</a
        >
        خليك فالبيت... خليك في آمان. اطلب اونلاين و الشحن مجاني داخل القاهره
        والجيزه فقط و الدفع عند الإستلام
      </div>
      <div></div>
    </div>

    <b-navbar toggleable="lg" type="dark" variant="dark" sticky="top">
      <!-- :style="{ marginTop: visible ? '50px' : '0px' }" -->
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/" class="active">الرئيسيه</b-nav-item>
          <template v-for="item of list">
            <b-nav-item
              :key="item.id"
              v-if="item.list"
              :to="`/category/${item.slug}`"
              >{{ item.name }}</b-nav-item
            >
            <b-nav-item-dropdown
              v-else
              :to="`/category/${item.slug}`"
              :text="item.name"
              right
            >
              <b-dropdown-item :to="`/category/${item.slug}`">
                الكل
              </b-dropdown-item>
              <b-dropdown-item
                v-for="itm of item.products"
                :key="itm.id"
                :to="'/product/' + itm.slug"
                >{{ itm.name }}</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </template>

          <!-- <b-nav-item href="#">اتصل بنا</b-nav-item> -->

          <b-nav-item to="/contactus" class="join"> اتصل بنا </b-nav-item>

          <b-nav-item href="#" class="login"> 01027743886 </b-nav-item>
        </b-navbar-nav>

        <b-navbar-brand to="/">
          <img
            class="img-fluid"
            src="../assets/logo.png"
            style="height: 50px"
          />
        </b-navbar-brand>
      </b-collapse>
    </b-navbar>

    <div class="container">
      <div class="row text-center">
        <div class="col-md-3 pt-3" style="direction: rtl">
          البريد الالكتروني
          <a href="mailto:info@mstec.org"> info@mstec.org </a>
        </div>
        <div class="col-md-3 pt-3">
          <p>
            شكاوى ومقترحات
            <a href="tel:01027743397">01027743397</a>
          </p>
        </div>
        <div class="col-md-3 pt-3">
          <p>
            خدمة العملاء
            <a href="tel:01027743844">01027743844</a>
          </p>
        </div>
        <div class="col-md-3 pt-3">
          <p>
            المبيعات
            <a href="tel:01027743886">01027743886</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-header",
  data() {
    return {
      visible: true,
    };
  },
  props: {
    list: [],
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
